<template>
  <div class="receipt">
    <div class="receipt_top">
      <img src="../../assets/receipt.png" alt />
      <div class="back" v-if="mode===0" @click="toHome">返回首页</div>
      <div class="back" v-if="mode===1" @click="toHome">返回订餐</div>
    </div>
  </div>
</template>

<script>
import { getPayTypeAPI } from "@/api/api";
export default {
  data() {
    return {
      mode: "",
      apiUrl: process.env.VUE_APP_BASE_API1,
    };
  },
  created() {
    let mchData = { action: "onIframeReady", displayStyle: "SHOW_CUSTOM_PAGE" };
    let postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");

    // this.apiUrl = this.apiUrl.split("api")[0];
    // console.log(window.location.href);

    getPayTypeAPI(this.$route.query.out_trade_no).then((res) => {
      if (res.code === 0) {
        if (res.data === "recharge") {
          this.mode = 0;
        } else {
          this.mode = 2;
        }
      }
    });
  },
  methods: {
    toHome() {
      // if (this.apiUrl.includes("zhst")) {
      //   this.apiUrl = this.apiUrl.replace("zhst", "icbc");
      // }
      if (this.mode === 0) {
        var mchData = {
          action: "jumpOut",
          jumpOutUrl: `${this.apiUrl}?weixinPayment=1`, //跳转的页面
        };
        var postData = JSON.stringify(mchData);
        parent.postMessage(postData, "https://payapp.weixin.qq.com");
      } else {
        var mchData = {
          action: "jumpOut",
          jumpOutUrl: `${this.apiUrl}abc/order?weixinPayment=1`, //跳转的页面
        };
        var postData = JSON.stringify(mchData);
        parent.postMessage(postData, "https://payapp.weixin.qq.com");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  background: rgba(255, 255, 255);
  width: 100%;
  height: 100%;
  .receipt_top {
    img {
      width: 100%;
    }
    .back {
      width: 200px;
      height: 50px;
      color: red;
      border: 1px solid red;
      line-height: 50px;
      text-align: center;
      border-radius: 40px;
      margin: 0 auto;
    }
  }
}
</style>